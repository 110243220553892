import React from 'react';
import { DATEPICKER, INPUT } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';

import './cmp_credit_activity_list.css';
import form_helper from 'libs/form-lib';


export default function CMP_CREDIT_ACTIVITY_LIST({ activities, onChange_activity, parent_id = null }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    //  event listeners ------------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    //  API calls ------------------------------------------------------------------------------------------------------

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_credit_value(activity, value) {
        activity.credits = form_helper.format_decimal_string(value, 2, 2);
        onChange_activity(activity);
    }

    function onChange_date(activity, value) {
        activity.activity_date = value;
        onChange_activity(activity);
    }

    // RENDER APP ======================================================================================================

    if (!activities || activities.length === 0) return;

    return (
    <>
        {activities.map((activity) => (
        <>
            {parent_id === null ? (
                <div key={activity.id} id='cmp_credit_activity_list'>
                    {activity.parent_credential_credit_id !== null
                    ? null : (
                        <div className='category_wrapper'>
                            <div className='category_header text--sm-medium'>
                                <div className='category_title'>{t('Task')}</div>
                                <div className='category_date'>{t('Date')}</div>
                                <div className='category_summary'>{t('Credits')}</div>
                            </div>

                            <div className='item_wrapper'>
                                <div style={{ paddingRight: "1rem" }} className={'item_description' + (activity.allow_credit_entry !== 'YES' ? ' text--md-medium' : '')}>
                                    {activity.description}
                                </div>
                                {activity.allow_credit_entry === 'YES' && (
                                    <>
                                        <DATEPICKER
                                            property='activity_date'
                                            value={activity.activity_date ? new Date(activity.activity_date) : ''}
                                            placeholder={t('YYYY-MM-DD')}
                                            onChange={((e, {value}) => onChange_date(activity, value))}
                                        />
                                        <div className='item_credits'>
                                            <INPUT
                                                property='credits'
                                                value={activity.credits ? String(activity.credits) : ''}
                                                onChange={((e, {value}) => onChange_credit_value(activity, value))}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <CMP_CREDIT_ACTIVITY_LIST
                                parent_id={activity.id}
                                activities={activities}
                                onChange_activity={onChange_activity}
                            />
                        </div>
                    )}
                </div>
            ) : parent_id === activity.parent_credential_credit_id ? (
                <>
                    <div key={activity.id} className='item_wrapper'>
                        <div style={{ paddingRight: '1rem' }}>
                            {activity.description}
                        </div>
                        {activity.allow_credit_entry === 'YES' && (
                            <>
                                <DATEPICKER
                                    property='activity_date'
                                    value={activity.activity_date ? new Date(activity.activity_date) : ''}
                                    placeholder={t('YYYY-MM-DD')}
                                    onChange={((e, {value}) => onChange_date(activity, value))}
                                />
                                <div className='item_credits'>
                                    <INPUT
                                        property='credits'
                                        value={activity.credits ? String(activity.credits) : ''}
                                        onChange={((e, {value}) => onChange_credit_value(activity, value))}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <CMP_CREDIT_ACTIVITY_LIST
                        parent_id={activity.id}
                        activities={activities}
                        onChange_activity={onChange_activity}
                    />
                </>
            ) : null}
        </>
    ))}
    </>
);
}