import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import { useTranslation } from 'react-i18next';
import Icon from 'components/cmp_icon';
import 'i18n';


import './invite.css';


export default function INVITE() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [var_invite, set_invite] = useState(null);
    const [var_invitestatus, set_invitestatus] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        let response = new URLSearchParams(window.location.search).get('response');
        let invitetoken = new URLSearchParams(window.location.search).get('id');
        if (response === 'DECLINED' && invitetoken) {
            update_declined(invitetoken);
        } else if (response === 'ACCEPT' && invitetoken){
            update_accepted(invitetoken);
        } else {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //  functions ------------------------------------------------------------------------------------------------------


    //  async functions ------------------------------------------------------------------------------------------------

    async function update_declined(invitetoken) {
        try {
            let invite = await API_put_invite_decline(invitetoken);
            set_invite(invite);
            set_invitestatus('DECLINED');
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                set_invite(e.response.data);
                set_invitestatus('ERROR');
            } else {
                console.log(e);
            }
        }
    }

    async function update_accepted(invitetoken) {
        try {
            let invite = await API_get_invitestatus(invitetoken);
            sessionStorage.setItem('invitestatus', JSON.stringify({...invite, inviteid: invitetoken, invitesource: 'INVITE'}));
            // If user is logged in, log them out first
            if (auth.iam.status === 'LOGGED IN') {
                await auth.logout(navigate);
            } else {
                navigate('/login');
            }
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                set_invite(e.response.data);
                set_invitestatus('ERROR');
            } else {
                console.log(e);
            }
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_put_invite_decline(inviteid) {
        return API.put('admin', '/put-invite-decline/' + inviteid);
    }

    function API_get_invitestatus(inviteid) {
        return API.get('admin', '/get-invitestatus/' + inviteid);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function render_declined() {
        return (
            <div className='card rounded-lg shadow-sm'>
                <div className='logo icon__error_lg'>
                    <Icon name='error'/>
                </div>
                <div className='display--xs-medium'>
                    <div style={{flexDirection: 'column', alignItems: 'center'}}>
                        <div>{t('Invitation declined')}</div>
                        <div className='text--sm-regular'>{t('You have declined the invitation from')} {var_invite.name}. {t('Thank you for your response.')}</div>
                    </div>
                </div>
            </div>
        );
    }

    function render_message() {
        return (<>
            {['Unable to find invite', 'The invite is no longer valid', 'The invite has expired'].includes(var_invite.message) &&
                <Message 
                    warning
                    icon={<Icon name='warning' className='icon' />}
                    content={<span>{t('Already registered?') + ' '}<Button type='button' className='tertiary' onClick={() => navigate('/login')}>{t('Click here')}</Button>{' ' + t('to sign in to your account.')}</span>}
                />
            }
            <div className='card rounded-lg shadow-sm'>
                <div className='logo icon__error_lg'>
                    <Icon name='error'/>
                </div>
                <div className='display--xs-medium'>
                    {
                        ['Unable to find invite', 'The invite is no longer valid', 'The invite has expired'].includes(var_invite.message)
                        ? <div style={{flexDirection: 'column', alignItems: 'center'}}>
                            <div>{t('No invitation found')}</div>
                            <span className='text--sm-regular invite-message-text'>
                                {t('The invitation may have expired, been revoked, or has already been used. Please') + ' '}
                                <a href='https://helpcenter.credivera.com/en/knowledge/kb-tickets/new' target='_blank' rel='noopener noreferrer'>{t('contact us')}</a>
                                {' ' + t('for additional support.')}
                            </span>
                          </div>
                        : <div className='text--sm-regular'>{t(var_invite.message)}</div>
                    }
                </div>
            </div>
        </>);
    }


    // RENDER APP ======================================================================================================

    return (
        <div className='crd__invite'>
            <div id='content' tabIndex='0'>
                {var_invitestatus === 'DECLINED'
                    ? render_declined()
                    : var_invitestatus === 'ERROR'
                    ? render_message()
                    : null
                }
            </div>
        </div>
    );

}