import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from 'libs/api-lib';
import 'i18n';

import Icon from 'components/cmp_icon';
import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import CRD_IDENTIY_VERIFICATIONS from './crd_identiy_verifications/crd_identiy_verifications';
import CRD_VERIFICATION_CREDITS from './crd_verification_credits/crd_verification_credits';


export default function ORG_IDENTITY_VERIFICATIONS() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const { t } = useTranslation('public');

    const [ var_credits, set_credits ] = useState({});
    const [ var_processing, set_processing ] = useState(false);
    const [ var_loadingerror, set_loadingerror ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
        populate_credits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credits() {
        set_loadingerror(false);
        set_processing(true);

        try {
            let results = await API_get_identity_verification_credits();
            set_credits({ ...results, available: results.total - results.allocated - results.used });
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }

        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_identity_verification_credits() {
        return API.get('identity-verification', '/get-identity-verification-credits');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[{ path: null, text: t('Organization') }]}
                current_page_text={t('Identity verification')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header page__header--background3'>
                    <h1 className='display--sm-regular'>{t('Identity verification')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>
                </div>
                <CRD_VERIFICATION_CREDITS credits={var_credits} processing={var_processing} loading_error={var_loadingerror} />
                <CRD_IDENTIY_VERIFICATIONS onChange={populate_credits} available_credits={var_credits.available} />
            </div>
        </div>
    )
};
