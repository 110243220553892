import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_INPUT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';


export default function MDL_CREDENTIAL({ display, onClose, individualprequalifier_id, prequalifier_name, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_current_credential, set_current_credential ] = useState(null);
    const [ var_credential_list, set_credential_list ] = useState([]);
    const [ var_selected_credential_id, set_selected_credential_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_error_description, set_error_description ] = useState(null);
    const [ var_warning_description, set_warning_description ] = useState(null);
    const [ var_warning_pii, set_warning_pii ] = useState(null);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_has_approved_credential, set_has_approved_credential ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="search"]:not([disabled]), input[type="radio"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_selected_credential_id(null);
            set_current_credential(null);
            set_credential_list([]);
            set_processing(false);
            set_error_description(null);
            set_warning_description(null);
            set_warning_pii(null);
            set_loading(true);
            load_credentials();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (!var_loading) {
            set_modal(document.querySelector('#mdl_submitcred'));
        }
    }, [var_loading]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function load_credentials() {
        try {
            let results = await API_get_credential_prequalifier_cred_list();
            set_current_credential(results.current_credential);
            results.current_credential?.id && set_selected_credential_id(results.current_credential?.id);
            let transformed = results.credential_list.map((item, index) => ({
                value: item.id ?? index.toString(),
                primary_text: item.credential,
                secondary_text: item.issuer,
                secondary_text_link: item.website,
                disabled: (item.verification_status !== 'VALID' || item.id === results.current_credential?.id),
                has_attachment: item.has_attachment
            }));
            set_credential_list(transformed);
            let approved_credentials = results.credential_list.filter(item => item.verification_status === 'VALID');
            if (approved_credentials.length === 0) {
                set_has_approved_credential(false);
                set_warning_description(t('You do not have an approved credential to submit.'));
            } else if (approved_credentials.length === 1 && approved_credentials[0].id === results.current_credential?.id) {
                set_has_approved_credential(false);
                set_warning_description(t('No alternative credential is available for submission to this pre-qualifier.'));
            } else {
                set_has_approved_credential(true);
                set_warning_description(t("Modifying the credentials may result in the pre-qualifier becoming incomplete. As a result, the status of your credential may change from 'Valid' to 'In progress' until the pre-qualifier is completed. This action cannot be undone."));
            }
        } catch (e) {
            console.log(e);
            set_error_description(t('An unexpected error occurred. Please try again.'));
        }
        set_loading(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_prequalifier_cred_list() {
        return API.get('credentials', '/get-credential-prequalifier-cred-list/' + individualprequalifier_id, { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_put_credential_prequalifier_credential() {
        return API.put('credentials', '/put-credential-prequalifier-credential', {
            queryStringParameters: { tz: datelib.timezone },
            body: { individualprequalifier_id, individual_credential_id: var_selected_credential_id }
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_credential(value) {
        set_error_description(null);
        set_selected_credential_id(value);

        if (var_credential_list.find(credential => credential.value === value).has_attachment === 'YES') {
            set_warning_pii(t('Credentials with attachments may include personally identifiable information, which will be visible to the issuer when the credential is submitted to the pre-qualifier.'));
        } else {
            set_warning_pii(null);
        }
    }

    async function onClick_save() {
        if (var_processing) return;

        // validation
        if (!var_selected_credential_id || var_selected_credential_id === var_current_credential?.id) {
            set_error_description(t('You must select a credential before submitting'));
            return;
        }

        set_processing(true);
        try {
            await API_put_credential_prequalifier_credential();
            onChange && onChange();
        } catch (exception) {
            console.log(exception);
            set_error_description(t('An unexpected error occurred. Please try again.'));
        }
        set_processing(false);
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            className='mdl__individualcredential_submit_prequalifiercredential'
            id='mdl_submitcred'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_submitcred'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_submitcred'>{var_current_credential ? t('Change credential') : t('Submit credential')}</div>
                </div>
            </div>
            <Form className='modal__content center padding--lg' tabIndex='0'>
                {var_error_description &&
                    <Message error
                        icon={<Icon name='error' className='icon' alt={t('alert icon')} />}
                        header={var_error_description} />
                }

                {!var_loading && var_warning_description &&
                    <Message warning
                        icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                        header={var_warning_description} />
                }

                {!var_loading && var_warning_pii &&
                    <Message warning
                        icon={<Icon name='warning' className='icon' alt={t('warning icon')} />}
                        header={var_warning_pii} />
                }

                {var_current_credential
                    ? <FORM_INPUT
                        property='current_credential'
                        label={t('Current credential')}
                        value={var_current_credential.name}
                        onChange={null}
                        placeholder={t('Current credential')}
                        disabled={true}
                    />
                    : <div style={{ marginBottom: '1rem' }}>{prequalifier_name}</div>
                }

                <div>
                    <FORM_SELECT_RADIO
                        name='credentials'
                        label={t('Approved credentials')}
                        value={var_selected_credential_id}
                        options={var_credential_list}
                        single_or_multiple='SINGLE'
                        onChange={onChange_credential} />
                </div>

            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    {!var_loading &&
                        <>
                            <Button className='primary' onClick={onClick_save} disabled={!var_has_approved_credential}>{t('Submit')}</Button>
                            <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                        </>
                    }
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );
}