import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DATEPICKER, FORM_INPUT, FORM_SELECT, FORM_TEXTAREA, FORM_TOGGLE } from 'components/cmp_form/cmp_form';
import Processing from 'components/cmp_processing';
import { NIL as NIL_UUID } from 'uuid';
import { useTranslation } from 'react-i18next';
import 'i18n';
import auth from 'libs/auth-lib';
import datelib from 'libs/date-lib';
import form_helper from 'libs/form-lib';
import lookup from 'libs/lookup-lib';

import CMP_FILEUPLOAD from 'components/cmp_fileupload/cmp_fileupload';

import './cmp_credential_newversion.css';


export default function CMP_NEWVERSION({ display, onClose, id, onChange, can_verify = false, is_admin = false, holder = null, assignment_id = null }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_footprint, set_footprint ] = useState({});
    const [ var_credential, set_credential ] = useState({});
    const [ var_fileupload_status, set_fileupload_status] = useState(null);
    const [ var_credential_to_save, set_credential_to_save ] = useState(null);
    const [ var_disabled, set_disabled ] = useState(false);

    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_confirmation_status_options, set_confirmation_status_options ] = useState([]);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_override_custom_workflow, set_override_custom_workflow ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_modal(document.querySelector('#mdl_newversion'));
            set_override_custom_workflow(false);
            set_footprint({});
            set_credential({});
            set_fileupload_status('RESET');
            set_save_error(null);
            set_errors([]);
            populate_credential();
            can_verify && populate_confirmation_status_options();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display]);

    useEffect(() => {
        if (var_fileupload_status === 'UPLOAD COMPLETE') {
            save_credential(var_credential_to_save);
        } else if (var_fileupload_status === 'UPLOAD FAIL') {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_fileupload_status]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credential() {
        let results = await API_get_credential_details();
        results = results.results[0];
        if (can_verify){
            results.confirmation_status = '';
            results.verification_status = '';
            results.comment = '';
        }
        set_credential(results);
        populate_footprint(results.credential_id);
    }

    async function populate_footprint(credential_id) {
        if (credential_id === NIL_UUID) {
            set_footprint({
                issuedrequired: 'NO',
                expires: 'YES',
                expiryrequired: 'NO',
                has_credential_number: 'YES',
                credential_number_required: 'NO',
                credential_number_label: t('Credential Number'),
                has_prequalifier: 'NO',
                contains_pii: 'NO'
            });
        } else {
            let footprint = await API_get_credential_newversion_info();
            set_disabled(footprint.custom_workflow_fields && footprint.custom_workflow_fields.disabled ? footprint.custom_workflow_fields.disabled : false);
            if (footprint.custom_workflow_fields && footprint.custom_workflow_fields.preload) {
                preload_custom_workflow(footprint.custom_workflow_fields.preload, footprint);
            }
            if (is_admin && footprint.custom_workflow_fields?.override_custom_workflow && footprint.custom_fields) {
                set_override_custom_workflow(true);
            }
            set_footprint(footprint);
        }
    }

    async function populate_confirmation_status_options() {
        set_confirmation_status_options(await lookup.confirmation_status.get_select_options({ include_extended_data: true }));
    }

    function preload_custom_workflow(type, footprint) {
        switch (type) {
            case 'preload_bis':
                set_credential({lastname: auth.lastname});
                break;
            case 'preload_wkt':
                let link_field = footprint.custom_workflow_fields.fields.find(item => item.type === 'static text');
                let new_link = <>{link_field.value[0]} <a href={link_field.value[1]} className='link' target='_blank' rel='noreferrer'>{link_field.value[2]}</a> {link_field.value[3]}</>
                link_field.value.splice(0, 4, new_link);
                break;
            default:
                throw new Error('Unknown type');
        }
    }

    async function save_credential(new_version) {
        try {
            let result = await API_put_credential(new_version);
            if (result && result.verification_succeeded === false) {
                set_save_error(t('Unable to find your credential.  Please check your inputs and try again.'));
            } else {
                onChange && onChange();
            }
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_newversion_info() {
        return API.get('credentials', '/get-credential-newversion-info/' + id);
    }

    function API_get_credential_details() {
        return API.get('credentials', '/get-credential-details/' + id + (assignment_id ? ('/' + assignment_id) : ''), { queryStringParameters: { tz: datelib.timezone }});
    }

    function API_put_credential(new_version) {
        if (var_footprint.API_service) {
            return API.put(var_footprint.API_service, '/' + var_footprint.API_put, {
                queryStringParameters: {
                    tz: datelib.timezone
                },
                body: new_version
            });
        }
        return API.put('credentials', is_admin ? '/put-holder-credential' + (assignment_id ? ('/' + assignment_id) : '') : '/put-credential', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: new_version
        });
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(e, { name, value }) {
        set_errors([]);
        set_save_error(null);
        let new_version = {...var_credential};
        switch (name) {
            case 'issued':
                if (value === new_version.issued) return;
                new_version.issued = value
                if (value !== null && var_footprint.expires === 'YES' && var_footprint.expires_unit) {
                    new_version.expires =
                        datelib.utcmoment_to_local_midnight_date(
                            datelib.localdate_to_utc_midnight_moment(value).add(var_footprint.expires_amount, var_footprint.expires_unit + 's')
                        );
                }
                break;
            default:
                new_version[name] = value;
                break;
        }
        set_credential(new_version);
    }

    function onChange_customField(name, value) {
        let custom_field = var_footprint.custom_fields.find(item => item.label === name);
        let new_version = { ...var_credential };
        let additional_fields = new_version.additional_fields ? JSON.parse(new_version.additional_fields) : [];
        switch (custom_field.type) {
            case 'Boolean':
                if (value === 'YES' && !additional_fields.some(item => item.label === name)) {
                    additional_fields.push({ label: name, value: 'Yes' });
                } else if (value === 'NO' && additional_fields.some(item => item.label === name)) {
                    additional_fields.splice(additional_fields.findIndex(item => item.label === name), 1);
                } else {
                    return;
                }
                break;
            default:
                throw new Error('Not implemented');
        }
        new_version.additional_fields = JSON.stringify(additional_fields);
        set_credential(new_version);
    }

    function onChange_document(details) {
        set_credential_to_save({...var_credential_to_save, filename: details.filename, submittedfilename: details.display_name});
    }

    async function onClick_save() {
        let errors = [];
        let new_version = {...var_credential};
        if (!var_override_custom_workflow) {
            if (var_footprint.custom_workflow_fields) {
                var_footprint.custom_workflow_fields.fields.forEach(item => {
                    if (!item.disable_new_version && item.required && !form_helper.validate_required_string(new_version[item.name])) {
                        errors.push({ property: item.name, description: (item.translate ? t(item.label) : item.label) + ' ' + t('is required')});
                    }
                });
            } else if (var_footprint.has_prequalifier !== 'YES') {
                if (var_footprint.issuedrequired === 'YES' && !new_version.issued) {
                    errors.push({ property: 'issued', description: t('Issued date is required') });
                }
                if (var_footprint.expires === 'YES' && var_footprint.expires_unit && var_footprint.expiryrequired !== 'NO' && !new_version.expires) {
                    errors.push({ property: 'expires', description: t('Expires date is required') });
                }
                if (var_footprint.has_credential_number === 'YES' && var_footprint.credential_number_required === 'YES' &&
                    !form_helper.validate_required_string(new_version.credential_number)) {
                    errors.push({ property: 'credential_number', description: var_footprint.credential_number_label + ' ' + t('is required') });
                }
                let date_validation;
                if (new_version.issued) {
                    date_validation = form_helper.validate_date(new_version.issued, null, new_version.expires);
                    if (date_validation.is_less_than_minimum) {
                        errors.push({ property: 'issued', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
                    } else if (date_validation.is_greater_than_maximum) {
                        errors.push({ property: 'issued', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
                    }
                }
                if (new_version.expires) {
                    date_validation = form_helper.validate_date(new_version.expires, new_version.issued);
                    if (date_validation.is_less_than_minimum) {
                        errors.push({ property: 'expires', description: t('This date is earlier than the minimum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.minimum, true) }) });
                    } else if (date_validation.is_greater_than_maximum) {
                        errors.push({ property: 'expires', description: t('This date exceeds the maximum allowed of {{date}}. Please input another date.', { date: datelib.iso_to_date_string(date_validation.maximum, true) }) });
                    }
                }
            }
            if (var_footprint.has_prequalifier !== 'YES' && can_verify && !form_helper.validate_required_string(new_version.confirmation_status)) {
                errors.push({ property: 'confirmation_status', description: t('Confirmation status is required') });
            }
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // create new object with only required properties
        let credential_to_save = {
            individualcredential_id: id,
            confirmation_status: var_credential.confirmation_status ? var_credential.confirmation_status.toUpperCase() : ''
        };
        if (can_verify) {
            credential_to_save.confirmation_status = new_version.confirmation_status;
            if (await lookup.confirmation_status.get_verification_status(new_version.confirmation_status) === 'INVALID') {
                credential_to_save.comment = new_version.comment;
                form_helper.cleanse_string_property(credential_to_save, 'comment');
            }
        }
        if (var_footprint.custom_workflow_fields && var_footprint.custom_workflow_fields.fields) {
            var_footprint.custom_workflow_fields.fields.forEach((item) => {
                if (!item.disable_new_version && item.name) {
                    credential_to_save[item.name] = new_version[item.name];
                    form_helper.cleanse_string_property(credential_to_save, item.name);
                }
            });
        } else {
            credential_to_save.issued = new_version.issued ? datelib.localdate_to_utc_midnight_epoch(new_version.issued) : null;
            credential_to_save.expires = (var_footprint.expires === 'YES' && new_version.expires) ? datelib.localdate_to_utc_midnight_epoch(new_version.expires) : null;
            if (var_footprint.has_credential_number === 'YES') {
                credential_to_save.credential_number = new_version.credential_number;
                form_helper.cleanse_string_property(credential_to_save, 'credential_number');
            }
            credential_to_save.pii = new_version.pii;
            form_helper.cleanse_string_property(credential_to_save, 'pii');
        }
        if (new_version.additional_fields) {
            credential_to_save.additional_fields = new_version.additional_fields;
        }
        set_credential_to_save(credential_to_save);

        // save
        set_processing(true);
        try {
            if (var_fileupload_status === 'UPLOAD READY') {
                set_fileupload_status('START UPLOAD');
            } else {
                save_credential(credential_to_save);
            }
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}
            id='mdl_newversion'
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_workerprofile_credential_update'
        >
            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='display--xs-medium' id='hdr_workerprofile_credential_update'>{t('Update credential')}</div>
                </div>
            </div>
            <Form className='modal__content' tabIndex='0' id='form_workerprofile_credential_update' aria-labelledby='hdr_workerprofile_credential_update'>
                {var_save_error &&
                    <Message error header={var_save_error} />
                }
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={t('There are some errors with your inputs')}
                    />
                }

                {!is_admin &&
                    <Message
                        info
                        icon={<Icon name='info' className='icon' />}
                        header={t('Organizations connected to you have access to view this credential. To manage access, navigate to the \'Access\' tab within the credential.')}
                    />
                }

                {is_admin && holder &&
                    <FORM_INPUT
                        property='holder'
                        label={t('Holder')}
                        value={holder}
                        placeholder={t('Holder')}
                        maxLength={100}
                        disabled={true}
                        errors={var_errors}
                    />
                }

                <FORM_INPUT
                    property='issuedby'
                    label={t('Issuer')}
                    value={var_credential.issuer_name}
                    placeholder={t('Issuer')}
                    maxLength={100}
                    disabled={true}
                    errors={var_errors}
                />

                {(var_footprint.custom_workflow_fields && var_footprint.custom_workflow_fields.fields && !var_override_custom_workflow)
                    ? render_custom_workflow_fields()
                    : render_standard_inputs()
                }
            </Form>
            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save} disabled={var_disabled && !var_override_custom_workflow}>{t('Update')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );

    function render_standard_inputs() {
        return (
            <>
                <FORM_INPUT
                    property='credential'
                    label={t('Credential')}
                    value={var_credential.credential_name}
                    placeholder={t('Credential')}
                    maxLength={100}
                    disabled={true}
                    errors={var_errors}
                />

                {var_footprint.has_prequalifier !== 'YES' &&
                    <FORM_DATEPICKER
                        property='issued'
                        label={t('Issued')}
                        value={var_credential.issued || ''}
                        onChange={(e, { value }) => onChange_input(e, {name: 'issued', value })}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('YYYY-MM-DD')}
                        minproperty={null}
                        maxproperty={var_credential.expires}
                        errors={var_errors}
                        disabled={var_disabled || var_override_custom_workflow}
                    />
                }

                {var_footprint.expires === 'YES' &&
                    <FORM_DATEPICKER
                        property='expires'
                        label={t('Expires')}
                        value={var_credential.expires || ''}
                        onChange={(e, { value }) => onChange_input(e, {name: 'expires', value })}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('YYYY-MM-DD')}
                        minproperty={var_credential.issued}
                        maxproperty={null}
                        disabled={var_footprint.has_prequalifier === 'YES' || var_disabled || var_override_custom_workflow}
                        errors={var_errors}
                    />
                }

                {var_footprint.has_credential_number === 'YES' &&
                    <FORM_INPUT
                        property='credential_number'
                        label={t(var_footprint.credential_number_label)}
                        value={var_credential.credential_number}
                        placeholder={var_footprint.credential_number_label}
                        onChange={onChange_input}
                        maxLength={100}
                        disabled={var_footprint.has_prequalifier === 'YES' || var_disabled || var_override_custom_workflow}
                        errors={var_errors}
                    />
                }

                {var_footprint.contains_pii === 'YES' &&
                    <FORM_TEXTAREA
                        property='pii'
                        label={t('PII')}
                        value={var_credential.pii || ''}
                        placeholder={t('PII')}
                        onChange={onChange_input}
                        maxLength={1000}
                        disabled={var_disabled || var_override_custom_workflow}
                        errors={var_errors}
                    />
                }

                {is_admin && var_footprint.custom_fields && render_custom_fields()}

                {(var_footprint.has_prequalifier !== 'YES' && !var_override_custom_workflow) &&
                    <>
                        {can_verify &&
                            <FORM_SELECT
                                property='confirmation_status'
                                name=''
                                label={t('Confirmation')}
                                value={var_credential.confirmation_status || ''}
                                onChange={onChange_input}
                                onOpen={() => set_modal_close_on_escape(false)}
                                onClose={() => set_modal_close_on_escape(true)}
                                placeholder={t('Select')}
                                options={var_confirmation_status_options}
                                errors={var_errors}
                            />
                        }

                        {can_verify && var_confirmation_status_options.some(item => item.value === var_credential.confirmation_status && item.verification_status === 'INVALID') &&
                            <FORM_TEXTAREA
                                property='comment'
                                label={t('Rejection note (optional)')}
                                value={var_credential.comment || ''}
                                onChange={onChange_input}
                                placeholder={t('Rejection note')}
                                maxLength={100}
                                disabled={false}
                                errors={var_errors}
                            />
                        }

                        {render_fileupload()}
                    </>
                }
            </>
        );
    }

    function render_custom_workflow_fields() {
        return (
            <>
                {var_footprint.custom_workflow_fields.fields.sort((a, b) => a.order - b.order).map((item, index) => {
                    if (item.disable_new_version) return null;
                    if (item.type === 'static text') {
                        return (
                            <div className='detailsgroup' key={'custom_field_' + index}>
                                <div className='detailsgroup__label text--sm-medium'>
                                    {item.translate ? t(item.label) : item.label}
                                </div>
                                <div className='text_wrapper'>
                                    {item.value.map((value, valueindex) =>
                                        <div className='text__property' key={'value_' + valueindex}>
                                            {value}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    } else if (item.type === 'image') {
                        return (
                            <div className='detailsgroup' key={'custom_field_' + index}>
                                <div className='detailsgroup__label text--sm-medium'>
                                {item.translate ? t(item.label) : item.label}
                                </div>
                                <img src={item.value} alt={item.alt} />
                            </div>
                        )
                    } else if (item.type === 'dropdown') {
                        return (
                            <FORM_SELECT
                                key={'custom_field_' + index}
                                property={item.name}
                                value={var_credential[item.name] || ''}
                                label={item.translate ? t(item.label) : item.label}
                                onChange={onChange_input}
                                onOpen={() => set_modal_close_on_escape(false)}
                                onClose={() => set_modal_close_on_escape(true)}
                                placeholder={item.translate ? t(item.placeholder) : item.placeholder}
                                options={item.options.map(option => ({ value: option.value, text: (item.translate_options) ? t(option.text) : option.text }))}
                                errors={var_errors}
                            />
                        )
                    } else {
                        return (
                            <FORM_INPUT
                                key={'custom_field_' + index}
                                property={item.name}
                                label={item.translate ? t(item.label) : item.label}
                                value={var_credential[item.name] || ''}
                                placeholder={item.translate ? t(item.placeholder) : item.placeholder}
                                onChange={onChange_input}
                                errors={var_errors}
                            />
                        );
                    }
                })}
            </>
        );
    }

    function render_custom_fields() {
        return var_footprint.custom_fields.map(item =>
            item.type === 'Boolean'
                ? <FORM_TOGGLE
                    key={`custom_field_${item.order}`}
                    property={`custom_field_${item.order}`}
                    label={item.translate ? t(item.label) : item.label}
                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                    value={(var_credential?.additional_fields && JSON.parse(var_credential.additional_fields).some(field => field.label === item.label)) ? 'YES' : 'NO'}
                    onChange={value => onChange_customField(item.label, value)}
                />
                : null
        );
    }

    function render_fileupload() {
        return (
            <div className='detailsgroup'>
                <div className='detailsgroup__label text--sm-medium'>{t('Document')}</div>
                <div className='fileupload__wrapper'>
                    <CMP_FILEUPLOAD
                        var_status={var_fileupload_status}
                        set_status={set_fileupload_status}
                        onChange_details={onChange_document}
                        single_or_multiple='MULTIPLE'
                        uploaddirectory='certificate' max_size={10} allowed_fileextensions={['pdf', 'png', 'jpg', 'jpeg']}
                        instructions={t('Upload any documents you may have to support your credential claim. Only PDF, JPG, and PNG file types are accepted.')} />
                </div>
            </div>
        );
    }

}