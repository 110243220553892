import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Message, Form, Button } from 'semantic-ui-react';
import { COLORPICKER, TOGGLE, SELECT } from 'components/cmp_form/cmp_form';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import Icon from 'components/cmp_icon';
import CMP_CERTIFICATE from 'components/cmp_certificate/cmp_certificate';
import CMP_CREDENTIAL_CARD from 'components/cmp_credential_card/cmp_credential_card';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import Processing from 'components/cmp_processing';
import config from 'config';
import 'i18n';

import './tab_design.css';



export default function TAB_DESIGN() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_display_type, set_display_type ] = useState('CERTIFICATE');
    const [ var_saved_custom_data, set_saved_custom_data ] = useState({});
    const [ var_custom_data, set_custom_data ] = useState({});
    const [ var_default_data, set_default_data ] = useState({});
    const [ var_confirmation_open, set_confirmation_open ] = useState(false);
    const [ var_logo_options, set_logo_options ] = useState([]);

    const [ var_message_text, set_message_text ] = useState(null);
    const [ var_message_type, set_message_type ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_storage_endpoint, set_storage_endpoint ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_logo_options();
        populate_endpoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        set_custom_data({});
        populate_data();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_display_type]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_logo_options() {
        try {
            let results = await API_get_org_logos();
            let transformed = results.filter(item => item.scan_status === 'CLEAN').map(item => ({
                value: item.logo_id,
                text: item.submittedfilename,
                logo: item.logo
            }));
            set_logo_options(transformed);
        } catch (e) {
            console.log(e);
        }
    }

    async function populate_data() {
        set_processing(true);
        try {
            let data = await API_get_branding();
            set_default_data(data.default_branding);
            set_custom_data(data.custom_branding ?? data.default_branding);
            set_saved_custom_data(data.custom_branding ?? data.default_branding);

            if (var_focuselement?.id) {
                let observer = new MutationObserver(function() {
                    let focuselement = document.querySelector(`#${var_focuselement.id}`);
                    if (document.contains(focuselement)) {
                        focuselement.focus();
                        observer.disconnect();
                    } else {
                        document.querySelector('#crd_cred_design').focus();
                        observer.disconnect();
                    }
                });
                observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
            }
        } catch (e) {
            console.log(e);
        }
        set_processing(false);
    }

    async function populate_endpoint() {
        set_storage_endpoint(await API.endpoint('storage'));
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_org_logos() {
        return API.get('org-profile', '/get-org-logos');
    }

    function API_get_branding() {
        return API.get('issuer', `/get-branding/${var_display_type}`);
    }

    function API_post_branding() {
        let body = var_display_type === 'CERTIFICATE' ? {
            display_type: 'CERTIFICATE',
            background_color: var_custom_data.background_color,
            header_color: var_custom_data.header_color,
            text_color: var_custom_data.text_color,
            link_color: var_custom_data.link_color,
            description_color: var_custom_data.description_color,
            logo_id: var_custom_data.logo_id
        } : {
            display_type: 'CARD',
            background_color: var_custom_data.background_color,
            text_color: var_custom_data.text_color,
            logo_id: var_custom_data.logo_id,
            logo_uri: var_custom_data.issuer_logo && `${var_storage_endpoint}/logo/${var_custom_data.issuer_id}/${var_custom_data.issuer_logo}`
        }
        return API.post('issuer', '/post-branding', { body });
    }

    function API_put_branding() {
        let body = var_display_type === 'CERTIFICATE' ? {
            credential_branding_id: var_custom_data.id,
            background_color: var_custom_data.background_color,
            header_color: var_custom_data.header_color,
            text_color: var_custom_data.text_color,
            link_color: var_custom_data.link_color,
            description_color: var_custom_data.description_color,
            logo_id: var_custom_data.logo_id
        } : {
            credential_branding_id: var_custom_data.id,
            background_color: var_custom_data.background_color,
            text_color: var_custom_data.text_color,
            logo_id: var_custom_data.logo_id,
            logo_uri: var_custom_data.issuer_logo && `${var_storage_endpoint}/logo/${var_custom_data.issuer_id}/${var_custom_data.issuer_logo}`
        }
        return API.put('issuer', '/put-branding', { body });
    }

    function API_delete_branding() {
        return API.del('issuer', `/delete-branding/${var_custom_data.id}`);
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_displayType(value, toggle_id) {
        set_focuselement(document.getElementById(toggle_id));
        set_message_text(null);
        set_display_type(value);
    }

    function onChange_branding(e, { name, value }) {
        set_message_text(null);
        let custom_data = { ...var_custom_data };
        if (name === 'logo_id') {
            custom_data.logo_id = value;
            custom_data.issuer_logo = var_logo_options.find(item => item.value === value).logo;
        } else {
            custom_data[name] = value;
        }
        set_custom_data(custom_data);
    }

    async function onClick_save() {
        set_message_text(null);
        set_processing(true);
        try {
            if (var_custom_data.targetentity === 'DEFAULT') {
                // new branding
                await API_post_branding();
                await populate_data();
            } else {
                // updated branding
                await API_put_branding();
                set_saved_custom_data({ ...var_custom_data });
                set_processing(false);
            }
        } catch {
            set_message_type('ERROR');
            set_message_text(t('An unknown error has occurred. Please try again.'));
            set_processing(false);
        }
    }

    function onClick_cancel() {
        set_message_text(null);
        set_custom_data({ ...var_saved_custom_data });
    }

    function onClick_restore() {
        set_message_text(null);
        set_confirmation_open(true);
    }

    async function onConfirm_restore() {
        if (var_custom_data.targetentity === 'DEFAULT') {
            // if there is no saved custom branding then just reset form
            set_confirmation_open(false);
            set_custom_data({ ...var_saved_custom_data });
            return;
        }

        set_processing(true);
        try {
            set_confirmation_open(false);
            await API_delete_branding();
            set_saved_custom_data({ ...var_default_data });
            set_custom_data({ ...var_default_data });
        } catch {
            set_message_type('ERROR');
            set_message_text(t('An unknown error has occurred. Please try again.'));
        }
        set_processing(false);
    }

    function onClick_profile(event) {
        event.preventDefault();
        navigate('/org/profile');
    }


    // RENDER APP ======================================================================================================

    return (!auth.has_orgaccess('ORG-ISSUER-ADMIN', 'branding') ? null :
        <div className='card rounded-lg shadow-sm' id='crd_cred_design' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div id='hdr_designer' className='text--xl-medium'>{t('Credential designer')}</div>
                    <div className='text--sm-regular'>{t("Customize your organization's credential certificates and digital cards with your company logo and brand colours.")}</div>
                </div>
            </div>

            <Form className='card__content' id='form_designer' aria-labelledby='hdr_designer'>
                <TOGGLE options={[{ value: 'CERTIFICATE', text: t('Certificate') }, { value: 'CARD', text: t('Digital card') }]} value={var_display_type} onChange={onChange_displayType} />

                <div className={'crd_body ' + ((!!var_custom_data.id && var_display_type === 'CERTIFICATE') ? 'certificate' : 'digitalcard')}>

                    <div className='crd_form'>
                        {var_display_type === 'CERTIFICATE' ? render_certificate_form() : render_card_form()}
                    </div>

                    {(!!var_custom_data.id && var_display_type === 'CERTIFICATE') ?
                        <CMP_CERTIFICATE display_type='inline' certificate={{
                            issuer_id: var_custom_data.issuer_id,
                            issuer_name: var_custom_data.issuer_name,
                            issuer_logo: var_custom_data.issuer_logo,
                            is_self_verified: 'NO',
                            has_prequalifier: 'NO',
                            credits_required_for_issuance: 'NO',
                            verification_status: 'VALID',
                            confirmation_status: t('Status'),
                            credential_name: t('Credential name'),
                            issued: 'YYYY-MM-DD',
                            expires: 'YYYY-MM-DD',
                            individual_name: t('FirstName LastName'),
                            verifying_organization_name: t('Verifier name'),
                            credential_document: 'File',
                            credential_id: '',
                            credential_logo: `${config.images.url}${config.images.assets.sample_credential_logo}${config.images.stage}`,
                            credential_description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus senectus lacinia nisi et.',
                            credential_skills: 'Lorem ipsum dolor sit amet consectetur. Rhoncus senectus lacinia nisi et.',
                            background_color: var_custom_data.background_color,
                            header_color: var_custom_data.header_color,
                            text_color: var_custom_data.text_color,
                            link_color: var_custom_data.link_color,
                            description_color: var_custom_data.description_color
                        }} />
                        : !!var_custom_data.id ?
                        <div className='card_view'>
                            <div className='text--sm-medium'>{t('Credivera card')}</div>
                            <CMP_CREDENTIAL_CARD type='Credivera' credential={{
                                id: '00000000-0000-0000-0000-000000000000',
                                issuer_id: var_custom_data.issuer_id,
                                issuer_name: var_custom_data.issuer_name,
                                logo: var_custom_data.issuer_logo,
                                verification_status: 'VALID',
                                confirmation_status: t('Status'),
                                credential_name: t('Credential name'),
                                issued: 'YYYY-MM-DD',
                                expires: 'YYYY-MM-DD',
                                prequalifier_status: null,
                                background_color: var_custom_data.background_color,
                                text_color: var_custom_data.text_color
                            }} />
                            {var_default_data?.has_verifiable_credentials_feature === 'YES' &&
                                <>
                                    <div className='text--sm-medium entra_card'>{t('Microsoft Authenticator')}</div>
                                    <CMP_CREDENTIAL_CARD type='Entra' credential={{
                                        id: '00000000-0000-0000-0000-000000000000',
                                        issuer_id: var_custom_data.issuer_id,
                                        issuer_name: var_custom_data.issuer_name,
                                        logo: var_custom_data.issuer_logo,
                                        verification_status: 'VALID',
                                        confirmation_status: t('Status'),
                                        credential_name: t('Credential name'),
                                        issued: 'YYYY-MM-DD',
                                        expires: 'YYYY-MM-DD',
                                        prequalifier_status: null,
                                        background_color: var_custom_data.background_color,
                                        text_color: var_custom_data.text_color
                                    }} />
                                </>
                            }
                        </div>
                        : null
                    }
                </div>
            </Form>

            <CMP_CONFIRMATION display={var_confirmation_open} title={t('Restore default certificate design')}
                message={
                    <>
                        <div>{t('This action will undo your customizations and revert the certificate to the default design.')}</div>
                        <div>{t('This action cannot be undone.')}</div>
                    </>}
                positive_option={t('Restore default')} negative_option={t('Cancel')} onConfirm={onConfirm_restore} onCancel={() => set_confirmation_open(false)} />

            <Processing display={var_processing} processingtext={t('Processing')} />
        </div>
    );

    function render_certificate_form() {
        return (
            <>
                <div className='text--sm-regular'>{t('Customizations will be applied to all credentials issued by your organization.')}</div>

                {render_message()}

                <div className='inputs_row' style={{ flexDirection: 'column' }}>
                    <div className='detailsgroup__label text--sm-medium'>{t('Organization logo')}</div>
                    <SELECT
                        property='logo_id'
                        value={var_custom_data.logo_id || ''}
                        onChange={onChange_branding}
                        placeholder={t('Select logo')}
                        options={var_logo_options}
                    />
                    <div className='org_profile_link_wrapper'>{t('Go to your profile to')} <a tabIndex='0' href='/' onClick={onClick_profile}>{t('edit or add additional logos')}</a> {t('for your organization.')} {t('Any unsaved changes will be lost.')}</div>
                </div>
                <div className='inputs_row'>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_background_color'>{t('Background color')}</label></div>
                        <COLORPICKER property='background_color' value={var_custom_data.background_color ?? '#ffffff'} onChange={onChange_branding} id='cp_background_color' />
                    </div>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_header_color'>{t('Credential and name')}</label></div>
                        <COLORPICKER property='header_color' value={var_custom_data.header_color ?? '#ffffff'} onChange={onChange_branding} id='cp_header_color' />
                    </div>
                </div>
                <div className='inputs_row'>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_text_color'>{t('Credential details')}</label></div>
                        <COLORPICKER property='text_color' value={var_custom_data.text_color ?? '#ffffff'} onChange={onChange_branding} id='cp_text_color' />
                    </div>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_link_color'>{t('Links')}</label></div>
                        <COLORPICKER property='link_color' value={var_custom_data.link_color ?? '#ffffff'} onChange={onChange_branding} id='cp_link_color' />
                    </div>
                </div>
                <div className='inputs_row'>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_description_color'>{t('Description and skills')}</label></div>
                        <COLORPICKER property='description_color' value={var_custom_data.description_color ?? '#ffffff'} onChange={onChange_branding} id='cp_description_color' />
                    </div>
                </div>

                <div className='spacer'></div>

                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Save certificate')}</Button>
                    <Button className='secondary' onClick={onClick_cancel}>{t('Discard changes')}</Button>
                </div>

                <div className='card__header__left footer__btns text--anchor' onClick={onClick_restore} tabIndex={0}>
                    {t('Restore default design')}
                </div>
            </>
        );
    }

    function render_card_form() {
        return (
            <>
                <div className='text--sm-regular'>
                    {var_default_data?.has_verifiable_credentials_feature === 'YES'
                        ? t('Customizations apply to all credentials issued by your organization but not retroactively to a verifiable credential.')
                        : t('Customizations apply to all credentials issued by your organization.')
                    }
                </div>

                {render_message()}

                <div className='inputs_row' style={{ flexDirection: 'column' }}>
                    <div className='detailsgroup__label text--sm-medium'>{t('Organization logo')}</div>
                    <SELECT
                        property='logo_id'
                        value={var_custom_data.logo_id || ''}
                        onChange={onChange_branding}
                        placeholder={t('Select logo')}
                        options={var_logo_options}
                    />
                    <div className='org_profile_link_wrapper'>{t('Go to your profile to')} <a tabIndex='0' href='/' onClick={onClick_profile}>{t('edit or add additional logos')}</a> {t('for your organization.')}</div>
                </div>
                <div className='inputs_row'>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_background_color'>{t('Background color')}</label></div>
                        <COLORPICKER property='background_color' value={var_custom_data.background_color ?? '#ffffff'} onChange={onChange_branding} id='cp_background_color' />
                    </div>
                    <div>
                        <div className='detailsgroup__label text--sm-medium'><label htmlFor='cp_text_color'>{t('Text color')}</label></div>
                        <COLORPICKER property='text_color' value={var_custom_data.text_color ?? '#ffffff'} onChange={onChange_branding} id='cp_text_color' />
                    </div>
                </div>

                <div className='spacer'></div>

                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Save card')}</Button>
                    <Button className='secondary' onClick={onClick_cancel}>{t('Discard changes')}</Button>
                </div>

                <div className='card__header__left footer__btns text--anchor' onClick={onClick_restore} tabIndex={0}>
                    {t('Restore default design')}
                </div>
            </>
        );
    }

    function render_message() {
        if (!!var_message_text) {
            return <Message success={var_message_type === 'SUCCESS'} error={var_message_type === 'ERROR'}
                icon={<Icon name={var_message_type === 'SUCCESS' ? 'checkmark' : 'error'} className='icon' />}
                header={var_message_text} />
        }
        return null;
    }

}