import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { Button, Message } from 'semantic-ui-react';
import { CHECKBOX } from 'components/cmp_form/cmp_form';
import Icon from 'components/cmp_icon';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import Processing from 'components/cmp_processing';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import 'i18n';

import './tab_access.css';


export default function TAB_ACCESS({ credential, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_access, set_access ] = useState([]);
    const [ var_updated_access, set_updated_access ] = useState([]);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_mode, set_mode ] = useState('VIEW');
    const [ var_mdl_confirmation_open, set_mdl_confirmation_open ] = useState(false);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_confirmation_title, set_confirmation_title ] = useState(null);
    const [ var_confirmation_message_requirements, set_confirmation_message_requirements ] = useState(null);
    const [ var_confirmation_message_credentials, set_confirmation_message_credentials ] = useState(null);
    const [ var_confirmation_message_sharing, set_confirmation_message_sharing ] = useState(null);
    const [ var_save_error, set_save_error ] = useState(null);
    const var_populateconfig = { ...default_populateconfig, limit: 100, sortby: 'organization_name' };

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'ACCESS' && credential.id) {
            populate_access();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab, credential.id]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_access() {
        set_loading(true);
        set_loadingerror(false);

        try {
            let results = await API_get_individual_credential_access();
            set_access(results);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }
        set_loading(false);
        set_ready(true);
    }

    async function update_access() {
        if (var_processing) return;
        set_mdl_confirmation_open(false);
        set_processing(true);
        try {
            await API_put_individual_credential_access();
            populate_access();
            set_mode('VIEW');
        } catch (e) {
            console.log(e);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
        document.querySelector('#crd_access').focus();
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individual_credential_access() {
        return API.get('credentials', '/get-individual-credential-access/' + credential.id
        );
    }

    function API_put_individual_credential_access() {
        return API.put('credentials', '/put-individual-credential-access/' + credential.id,
            {
                queryStringParameters: {
                    tz: datelib.timezone
                },
                body: {
                    updated_access: var_updated_access
                }
            });
    }

    function API_get_usage() {
        return API.get('credentials', '/get-usage/' + credential.id);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_updated_access(var_access.map(item => { return {...item} }));
        set_mode('EDIT');
    }

    function onChange_access(id, property) {
        set_save_error(null);
        let updated_access = var_updated_access.map(item => { return {...item} });

        let current_access = updated_access.find(item => item.id === id);
        current_access[property] = current_access[property] === 'YES' ? 'NO' : 'YES';
        if (property === 'read_access' && current_access[property] === 'NO') {
            current_access['write_access'] = 'NO';
            current_access['share_access'] = 'NO';
        } else if ((current_access['write_access'] === 'YES' || current_access['share_access'] === 'YES') && current_access['read_access'] === 'NO') {
            current_access['read_access'] = 'YES';
        }

        set_updated_access(updated_access.map(item => { return {...item} }));
    }

    async function onClick_save() {

        let read_access_change = false;
        let share_access_change = false;
        let affected_organization_ids = [];

        for (let updated_access of var_updated_access) {

            if (updated_access.read_access === 'NO' && var_access.find(item => item.id === updated_access.id).read_access === 'YES') {
                read_access_change = true;
                affected_organization_ids.push(updated_access.organization_id);
            }
            if (updated_access.share_access === 'NO' && var_access.find(item => item.id === updated_access.id).share_access === 'YES') {
                share_access_change = true;
            }
        }

        if (share_access_change) {
            set_confirmation_message_sharing(<div>{t('Disabling sharing for this credential may affect reports created by the organization that include it.')}</div>);
        }

        let requirements = [];
        let credentials = [];

        if (read_access_change) {

            // get usage
            let usage = await API_get_usage();
            // get unique requirements that have been accepted
            requirements = [...new Set(usage.filter(item => item.individualrequirement_status === 'VALID' && affected_organization_ids.includes(item.organization_id)).map(item => item.requirement_name))];

            // get stacked credentials where this is used
            credentials = [...new Set(usage.filter(item => item.targetentity_type === 'CREDENTIAL' && affected_organization_ids.includes(item.organization_id)).map(item => item.targetentity_name))].sort();

            if (requirements.length > 0) {
                set_confirmation_message_requirements(
                    <div style={{ display: 'inline' }}>{t('This action will cause')} <span className='text--sm-bold'>{requirements.join(', ')}</span> {t('to become unmet, which could affect your ability to work.')}</div>
                );
            }
            if (credentials.length > 0) {
                set_confirmation_message_credentials(
                    <div style={{ display: 'inline' }}>{requirements.length > 0 ? t('Additionally, this will make a pre-qualifier for') : t('This will make a pre-qualifier for')} <span className='text--sm-bold'>{credentials.join(', ')}</span> {t('to become unmet, resulting in a status change to ‘In-progress’ for the credential.')}</div>
                );
            }
        }

        if (requirements.length > 0 && credentials.length > 0) {
            set_confirmation_title(t('Removing access will affect credential and requirement status.'));
        } else if (requirements.length > 0) {
            set_confirmation_title(t('Removing access will affect requirement status'));
        } else if (credentials.length > 0) {
            set_confirmation_title(t('Removing access will affect credential status'));
        } else if (share_access_change) {
            set_confirmation_title(t('Removing access may affect the organization\'s reports.'));
        }

        if ((requirements.length > 0 || credentials.length > 0) || share_access_change) {
            set_mdl_confirmation_open(true);
        } else {
            await update_access();
        }
    }

    function onClick_cancel() {
        set_save_error(null);
        set_updated_access(var_access.map(item => { return {...item} }));
        set_mode('VIEW');
        document.querySelector('#crd_access').focus();
    }

    function onCancel_confirmation() {
        set_confirmation_message_credentials(null);
        set_confirmation_message_requirements(null);
        set_confirmation_message_sharing(null);
        set_confirmation_title(null);
        set_mdl_confirmation_open(false);
        document.querySelector('#crd_access').focus();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm' id='crd_access' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_access' className='text--xl-medium'>{t('Access')}</div>
                    </div>
                    <div className='card__header__right'>
                        {var_mode === 'VIEW' &&
                            <Button data-testid='edit_button' className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                        }
                    </div>
                </div>

                <div className='text--sm-regular'>{t('Specify which organizations have access to view, edit, and share this credential.')}</div>

                {var_mode === 'EDIT' &&
                    <Message
                        warning
                        icon={<Icon name='warning' className='icon' alt={t('information icon')} />}
                        header={
                            <div style={{ flexDirection: 'column' }}>
                                <div>{t('You cannot remove access from the organization that manages your wallet.')}</div>
                                {var_access.some(item => item.org_manages_credentials === 'YES') &&
                                    <div style={{ marginTop: '0.75rem' }}>{t('Revoking an organization\'s view access will also remove its \'Edit\' and \'Share\' permissions.')}</div>
                                }
                            </div>
                        }
                        data-testid='credential_access_warning_message'
                    />
                }

                {var_save_error &&
                    <Message
                        error
                        icon={<Icon name='error' className='icon' alt={t('error icon')} />}
                        header={var_save_error}
                        data-testid='save_error_message'
                    />
                }

                <Table id='access' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={false} totalrows={var_access.length}
                        populateconfig={var_populateconfig} populatefilterfunction={() => null}
                        downloadname={t('Access')} downloadfunction={() => null}
                        onChange={() => null} hide_tablecontrols>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='organization_name' datatype='text' filtertype='text'>{t('ORGANIZATION')}</Table.HeaderCell>
                            <Table.HeaderCell field='read_access_display' datatype='text' filtertype='option'>{t('VIEW')}</Table.HeaderCell>
                            <Table.HeaderCell field='write_access_display' datatype='text' filtertype='option'>{t('EDIT')}</Table.HeaderCell>
                            <Table.HeaderCell field='share_access_display' datatype='text' filtertype='option'>{t('SHARE')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    {var_mode === 'VIEW' ?
                        <Table.Body>
                            {var_access.map(item =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        {`${item.organization_name}
                                        ${item.org_manages_credentials === 'YES' || item.is_admin_entered === 'YES'
                                            ? `(${t('Credential manager')})`
                                            : item.is_issuer === 'YES'
                                            ? `(${t('Credential issuer')})`
                                            : ''
                                        }`}
                                    </Table.Cell>
                                    <Table.Cell className='cell__icon cell--center'>
                                            {item.read_access === 'YES' ?  <Icon data-testid={'view_checkmark_' + item.id} name='checkmark' className='color--green-500' alt={t('Yes')}/> : null}
                                        </Table.Cell>
                                    <Table.Cell className='cell__icon cell--center'>
                                        {item.write_access === 'YES' ? <Icon data-testid={'edit_checkmark_' + item.id} name='checkmark' className='color--green-500' alt={t('Yes')}/> : null}
                                    </Table.Cell>
                                    <Table.Cell className='cell__icon cell--center'>
                                        {item.share_access === 'YES' ? <Icon data-testid={'share_checkmark_' + item.id} name='checkmark' className='color--green-500' alt={t('Yes')}/> : null}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    :
                        <Table.Body>
                            {var_updated_access.map(item =>
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        {`${item.organization_name}
                                        ${item.org_manages_credentials === 'YES' || item.is_admin_entered === 'YES'
                                            ? `(${t('Credential manager')})`
                                            : item.is_issuer === 'YES'
                                            ? `(${t('Credential issuer')})`
                                            : ''
                                        }`}
                                    </Table.Cell>
                                    <Table.Cell className='cell--checkbox'>
                                            <CHECKBOX data-testid={'view_checkbox_' + item.id} name='change_read_access' style={{ 'margin': 'auto' }} label={null} disabled={item.is_issuer === 'YES' || item.is_admin_entered === 'YES' || item.org_manages_credentials === 'YES'} checked={var_updated_access.find(access => access.id === item.id).read_access === 'YES'} onChange={() => onChange_access(item.id, 'read_access')}/>
                                        </Table.Cell>
                                    <Table.Cell className='cell--checkbox'>
                                        <CHECKBOX data-testid={'edit_checkbox_' + item.id} name='change_write_access' style={{ 'margin': 'auto' }} label={null} disabled={item.is_issuer === 'YES'|| item.is_admin_entered === 'YES' || item.org_manages_credentials === 'YES'} checked={var_updated_access.find(access => access.id === item.id).write_access === 'YES'} onChange={() => onChange_access(item.id, 'write_access')}/>
                                    </Table.Cell>
                                    <Table.Cell className='cell--checkbox'>
                                        <CHECKBOX data-testid={'share_checkbox_' + item.id} name='change_share_access' style={{ 'margin': 'auto' }} label={null} disabled={(item.is_issuer === 'YES' && item.user_belongs_to_org !== 'YES') || item.org_manages_credentials === 'YES'} checked={var_updated_access.find(access => access.id === item.id).share_access === 'YES'} onChange={() => onChange_access(item.id, 'share_access')}/>
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    }
                </Table>
                <div className='tbl__permissions__legend'>
                    <div className='tbl__permissions__legend__item'><div className='text--sm-bold'>{t('View')}:&nbsp;</div>{t('Allow organization to view credential')}</div>
                    <div className='tbl__permissions__legend__item'><div className='text--sm-bold'>{t('Edit')}:&nbsp;</div>{t('Allow organization to edit credential details')}</div>
                    <div className='tbl__permissions__legend__item'><div className='text--sm-bold'>{t('Share')}:&nbsp;</div>{t('Allow organization to share credential on your behalf')}</div>
                </div>

                {var_mode === 'EDIT' &&
                    <div className='card__footer'>
                        <Button data-testid='save_button' className='primary' onClick={onClick_save}>{t('Save')}</Button>
                        <Button data-testid='cancel_button' className='secondary' onClick={onClick_cancel}>{t('Cancel')}</Button>
                    </div>
                }

                <Processing display={var_processing} processingtext={t('Processing')} />
            </div>

            <CMP_CONFIRMATION
                display={var_mdl_confirmation_open}
                title={var_confirmation_title}
                message={
                    <div style={ { flexDirection: 'column' } }>
                        {var_confirmation_message_sharing &&
                            <div style={{marginBottom: '0.5rem'}}>
                                {var_confirmation_message_sharing}
                            </div>
                        }
                        {var_confirmation_message_requirements &&
                            <div style={{marginBottom: '0.5rem'}}>{var_confirmation_message_requirements}</div>
                        }
                        {var_confirmation_message_credentials &&
                            <div style={{marginBottom: '0.5rem'}}>{var_confirmation_message_credentials}</div>
                        }
                    </div>}
                positive_option={t('Continue')}
                negative_option={t('Cancel')}
                onConfirm={update_access}
                onCancel={onCancel_confirmation}
                data-testid='confirmation_modal'
            />
        </>
    );
}