import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';

import Icon from 'components/cmp_icon';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

function MDL_ADD_INDIVIDUAL({ is_open, onClose, assignment, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_individual_options, set_individual_options ] = useState([]);
    const [ var_selected_individuals, set_selected_individuals ] = useState([]);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_errors, set_errors ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (is_open && assignment.location_id) {
            // reset and populate options
            set_individual_options([]);
            set_selected_individuals([]);
            set_loading(true);
            set_loadingerror(false);
            set_errors([]);
            set_processing(false);
            populate_individual_options();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, assignment.location_id]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individual_options() {
        try {
            let results = await API_get_individuals_to_add_to_location();
            let transformed = results.map(item => ({
                value: item.id,
                primary_text:
                            <CMP_VERIFIED_IDENTITY_DISPLAY
                                individual_name={item.individual_name}
                                verified_identity_credential_id={item.identity_verification_credential_id}
                                display_nonclickable_icon={true}
                            />,
                secondary_text: item.email,
                additional_search_values: [item.individual_name]
            }));
            set_individual_options(transformed);
            if (results.length === 0) {
                set_errors([{description: t('There are no available individuals to add')}])
            }
        } catch (e) {
            console.log(e);
            set_errors([{description: t('There was a problem loading.  Please try again later')}]);
            set_loadingerror(true);
        }
        set_loading(false);
    }

    async function add_individuals() {
        set_processing(true);
        try {
            await API_post_individual_locations();
            onChange && onChange();
        } catch (e) {
            set_errors([{description: t('There was a problem saving.  Please try again later')}]);
        }
        set_processing(false);
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals_to_add_to_location() {
        return API.get('org-indv', '/get-individuals-to-add-to-location/' + assignment.location_id);
    }

    function API_post_individual_locations() {
        return API.post('org-indv', '/post-individual-locations', {
            queryStringParameters: {
                tz: datelib.timezone
            },
            body: {
                location_id: assignment.location_id,
                individual_ids: var_selected_individuals
            }});
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_individuals(value) {
        set_selected_individuals(value);
        set_errors([]);
    }

    async function onClick_add() {
        if (var_selected_individuals.length === 0) {
            set_errors([{property: 'individuals', description: t('Please select at least one individual to add')}]);
            return;
        }

        await add_individuals();
    }


    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer={'inverted'}
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}>
            <div className='modal__header'>
                <div style={ { width: '100%' }}>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium' id='hdr_assignment_addindiv'>{t('Add individual')}</div>
                    </div>
                    <div className='modal__header__right'>
                        <div className="modal__header__closeicon color--primary-500">
                            <Icon onClick={onClose} name='xicon_nocircle' className='' alt={t('Close')} />
                        </div>
                    </div>
                </div>
            </div>
            <Form className='modal__content center padding--lg' id='form_assignment_addindiv' aria-labelledby='hdr_assignment_addindiv'>
                {var_errors.length > 0 &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_errors[0].description} />
                }
                {!var_loading && !var_loadingerror &&
                    <>
                        <div className='text--sm-regular margin--lg' id='label_assignment_addindiv'>{t('Select individuals to add to this assignment.')}</div>
                        <div className='margin--lg'>
                            <SELECT_RADIO
                                name='individuals'
                                value={var_selected_individuals}
                                options={var_individual_options}
                                single_or_multiple='MULTIPLE'
                                onChange={onChange_individuals}
                                aria_labelledby='label_assignment_addindiv' />
                        </div>
                    </>
                }
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_individual_options.length === 0}>{t('Add')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_ADD_INDIVIDUAL;