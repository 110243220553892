import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import Table, { default_populateconfig } from 'components/cmp_table/cmp_table';
import { Button, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';

import CMP_CREDIT_ACTIVITY_STATUS from 'components/cmp_credit_activity_status/cmp_credit_activity_status';
import CMP_CREDIT_ACTIVITY from 'components/cmp_credit_activity/cmp_credit_activity';
import CMP_CONFIRMATION from 'components/cmp_confirmation/cmp_confirmation';
import MDL_CREDIT_ACTIVITY_MANUAL_ENTRY from 'containers/org/credentials/credential_details/tab_holders/mdl_credit_activity_manual_entry/mdl_credit_activity_manual_entry';

export default function TAB_CREDIT_DETAILS({ credential, onChange, activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_credit_activities, set_credit_activities ] = useState([]);
    const [ var_totalrows, set_totalrows ] = useState(0);
    const [ var_ready, set_ready ] = useState(false);
    const [ var_loading, set_loading ] = useState(true);
    const [ var_loadingerror, set_loadingerror ] = useState(false);
    const [ var_populateconfig, set_populateconfig ] = useState({ ...default_populateconfig, sortby: 'activity_date', sortorder: 'descending' });
    // eslint-disable-next-line no-unused-vars
    const [ var_refreshtable, set_refreshtable ] = useState(false);
    const [ var_focuselement, set_focuselement ] = useState(null);

    const [ var_mdl_activity_details_open, set_mdl_activity_details_open ] = useState(false);
    const [ var_save_error, set_save_error ] = useState(null);
    const [ var_delete_confirmation_open, set_delete_confirmation_open ] = useState(false);
    const [ var_target_individual_credential_credit_activity_id, set_target_individual_credential_credit_activity_id ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_mdl_manual_entry_open, set_mdl_manual_entry_open ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'CREDITDETAILS') {
            populate_activities();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);

    useEffect(() => {
        if (!var_mdl_activity_details_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_credit_activities').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_activity_details_open]);

    useEffect(() => {
        if (!var_delete_confirmation_open && var_focuselement) {
            if (var_focuselement.isConnected) {
                var_focuselement.focus();
                set_focuselement(null);
            } else {
                document.getElementById('crd_credit_activities').focus();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_delete_confirmation_open]);

    useEffect(() => {
        if (!var_mdl_manual_entry_open && var_focuselement?.isConnected) {
            var_focuselement.focus();
            set_focuselement(null);
        } else {
            document.getElementById('crd_credit_activities').focus();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[var_mdl_manual_entry_open]);

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_activities(populateconfig, focuselement_id) {
        set_save_error(null);
        set_loading(true);
        set_loadingerror(false);

        populateconfig && set_populateconfig(populateconfig);
        let { limit, offset, sortby, sortorder, filter } = populateconfig || var_populateconfig;

        try {
            let results = await API_get_credential_credit_activities(limit, offset, sortby, sortorder, filter);
            set_totalrows(results.totalrows);
            set_credit_activities(results.results === undefined ? [] : results.results);
            set_loading(false);
            set_ready(true);
        } catch (e) {
            set_loadingerror(true);
            console.log(e);
        }

        if (focuselement_id) {
            let observer = new MutationObserver(function() {
                let focuselement = document.querySelector(`#${focuselement_id}`);
                if (document.contains(focuselement)) {
                    focuselement.focus();
                    observer.disconnect();
                } else {
                    document.querySelector('#form_summary').focus();
                    observer.disconnect();
                }
            });
            observer.observe(document, { attributes: false, childList: true, characterData: false, subtree:true });
        }
    }

    async function populate_filters(filtername, sortorder) {
        try {
            return (await API_get_credential_credit_activities(var_populateconfig.maxfilteritems, 0, filtername, sortorder, {}, filtername)).results;
        } catch (e) {
            console.log(e);
        }
    }

    async function download_records(limit, offset) {
        try {
            let { sortby, sortorder, filter } = var_populateconfig;
            return (await API_get_credential_credit_activities(limit, offset, sortby, sortorder, filter)).results;
        }  catch (e) {
            console.log(e);
            throw e;
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_credit_activities(limit, offset, sortby, sortorder, filter, filtername) {
        return API.post('credentials', '/get-credential-credit-activities/' + credential.id,
            {
                queryStringParameters: {
                    limit: limit,
                    offset: offset,
                    sortby: sortby,
                    sortorder: sortorder,
                    filtername: filtername,
                    tz: datelib.timezone,
                    status: 'ALL'
                },
                body: filter
            }
        );
    }

    function API_delete_credential_credit_activity() {
        return API.del('credentials', '/delete-credential-credit-activity/' + var_target_individual_credential_credit_activity_id,
            {
                queryStringParameters: {
                    tz: datelib.timezone
                }
            }
        );
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_activity(id) {
        set_save_error(null);
        set_focuselement(document.activeElement);
        set_target_individual_credential_credit_activity_id(id);
        set_mdl_activity_details_open(true);
    }

    function onChange_activity_details() {
        set_mdl_activity_details_open(false);
        populate_activities(null, var_focuselement?.id);
        onChange();
    }

    async function onConfirm_delete() {
        set_delete_confirmation_open(false);
        set_save_error(null);
        set_processing(true);
        try {
            await API_delete_credential_credit_activity();
            populate_activities();
        } catch (e) {
            set_save_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }

    function onClick_delete(id) {
        set_focuselement(document.activeElement);
        set_save_error(null);
        set_target_individual_credential_credit_activity_id(id);
        set_delete_confirmation_open(true);
    }

    function onClick_add_credit_activity() {
        set_focuselement(document.activeElement);
        set_mdl_manual_entry_open(true);
    }

    function onChange_manual_entry() {
        set_mdl_manual_entry_open(false);
        populate_activities();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            <div className='card rounded-lg shadow-sm card__credential_activity' id='crd_credit_activities' tabIndex='0'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_credit_activity' className='text--xl-medium'>{t('Credit details')}</div>
                    </div>
                    {credential.allow_individual_to_update_credit_tracking === 'YES' &&
                        <div className='card__header__right card__header__btns'>
                            <Button id='btn_addcredential' className='primary' disabled={!credential} onClick={() => onClick_add_credit_activity()}>{t('Submit credits')}</Button>
                        </div>
                    }
                </div>

                <Message
                    warning={credential.current_credits_obtained < credential.credits_required}
                    success={credential.current_credits_obtained >= credential.credits_required}
                    icon={<Icon name='warning' className='icon' />}
                    header={`${t('Credits completed:')} ${credential.current_credits_obtained}/${credential.credits_required} ${t('(Pending credits not included)')}`}
                />

                {var_save_error &&
                    <Message error header={var_save_error} />
                }

                <Table id='credit_activity_entries' loading={var_loading} ready={var_ready} loadingerror={var_loadingerror} lockcolumns={0} refresh={var_refreshtable} totalrows={var_totalrows}
                    populateconfig={var_populateconfig} populatefilterfunction={populate_filters}
                    downloadname={t('Credit activities')} downloadfunction={download_records}
                    onChange={populate_activities}>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell field='status_display' datatype='text' filtertype='option'>{t('STATUS')}</Table.HeaderCell>
                            <Table.HeaderCell field='activity_date' datatype='datetime' filtertype='date'>{t('DATE')}</Table.HeaderCell>
                            <Table.HeaderCell field='credential_credit_description' datatype='text' filtertype='text'>{t('DESCRIPTION')}</Table.HeaderCell>
                            <Table.HeaderCell field='credit_value' datatype='number' filtertype='number'>{t('CREDITS')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {var_credit_activities.map(item =>
                            <Table.Row key={item.id}>
                                <Table.Cell>
                                    <CMP_CREDIT_ACTIVITY_STATUS
                                        is_open={false}
                                        activity={item}
                                        onChange={() => null}
                                        onMessage={() => null}
                                        onClose={() => null}
                                        onOpen={() => null}
                                        can_edit={false}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    {datelib.epoch_to_date_string(item.activity_date, false)}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.credential_credit_description}
                                </Table.Cell>
                                <Table.Cell className={credential.allow_individual_to_update_credit_tracking === 'YES' ? 'cell__icon--right' : ''}>
                                    <div className={item.status !== 'VERIFIED' && credential.allow_individual_to_update_credit_tracking === 'YES' ? 'text--anchor' : ''}>
                                        <Button id='btn_credit_value' disabled={(item.status === 'VERIFIED' || credential.allow_individual_to_update_credit_tracking !== 'YES')} className='tertiary' onClick={() => onClick_activity(item.id)}>
                                            {item.credit_value}
                                        </Button>
                                    </div>
                                    {credential.allow_individual_to_update_credit_tracking === 'YES' && 
                                        <Button id='btn_delete_activity' disabled={item.status === 'VERIFIED'} className='tertiary' onClick={() => onClick_delete(item.id)}>
                                            <Icon name='delete' className={'icon__delete__table' + (item.status === 'VERIFIED' ? ' disabled' : '')} />
                                        </Button>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>

                {credential.allow_individual_to_update_credit_tracking === 'YES' &&
                    <div className='card__footer--mobilebtns'>
                        <Button id='btn_addcredential' className='primary' disabled={credential.invitestatus !== 'Archive'} onClick={() => onClick_add_credit_activity()}>{t('Submit credits')}</Button>
                    </div>
                }

                <CMP_CREDIT_ACTIVITY
                    is_open={var_mdl_activity_details_open}
                    onClose={() => set_mdl_activity_details_open(false)}
                    activity_id={var_target_individual_credential_credit_activity_id}
                    onChange={onChange_activity_details}
                    holder_name={credential.individual_name}
                    can_edit={true}
                />

                <CMP_CONFIRMATION
                    display={var_delete_confirmation_open}
                    title={t('Delete entry')}
                    message={t('Are you sure you want to delete this entry? This action cannot be undone.')}
                    positive_option={t('Delete')}
                    negative_option={t('Cancel')}
                    onConfirm={onConfirm_delete}
                    onCancel={() => set_delete_confirmation_open(false)}
                />

                <MDL_CREDIT_ACTIVITY_MANUAL_ENTRY
                    is_admin={false}
                    is_open={var_mdl_manual_entry_open}
                    credential_id={credential.credential_id}
                    individual_credential_id={credential.id}
                    holder_name={credential.individual_name}
                    onChange={onChange_manual_entry}
                    onClose={() => set_mdl_manual_entry_open(false)}
                />

                <Processing display={var_processing} processingtext={t('Processing')} />

            </div>
        </>
    );
}