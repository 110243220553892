import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import API from 'libs/api-lib';
import Icon from 'components/cmp_icon';
import { FORM_INPUT, FORM_TEXTAREA } from 'components/cmp_form/cmp_form';
import 'i18n';
import Processing from 'components/cmp_processing';


export default function CRD_DETAILS() {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');
    const navigate = useNavigate();

    const [ var_requirement, set_requirement ] = useState({});
    const [ var_errors, set_errors ] = useState([]);
    const [ var_save_error, set_save_error ] = useState();
    const [ var_processing, set_processing ] = useState(false);

    //  event listeners ------------------------------------------------------------------------------------------------

    //  async functions ------------------------------------------------------------------------------------------------

    async function insert_requirement(requirement) {
        set_processing(true);
        try {
            let requirement_id = await API_post_requirement(requirement);
            navigate('/org/requirements2/' + requirement_id);
        } catch (e) {
            console.log(e);
            set_save_error(t('There was a problem saving.  Please try again later'));
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_requirement(requirement) {
        return API.post('requirements2', '/post-org-requirement',
            {
                body: requirement
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event) {
        let requirement = {...var_requirement};
        requirement[event.target.name] = event.target.value;
        set_requirement(requirement);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== event.target.name)
            set_errors(errors);
        }
    }

    function onClick_save() {
        set_save_error(false);
        let errors = [];
        let requirement = {...var_requirement};
        if (!form_helper.validate_required_string(requirement.name)) {
            errors.push({ property: 'name', description: t('Please provide a name for the requirement.') });
        }

        set_errors(errors);
        if (errors.length > 0) return;

        form_helper.cleanse_string_property(requirement, 'name');
        form_helper.cleanse_string_property(requirement, 'description');
        set_requirement(requirement);

        insert_requirement(requirement);
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_cr8requirement_details' tabIndex='0'>
            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_cr8requirement_details'>{t('Requirement details')}</div>
                    <div className='text--sm-regular'>{t('Set the name and description for the requirement.')}</div>
                </div>
            </div>
            <Message
                warning
                icon={<Icon name='warning' className='icon' />}
                header={t('This requirement will stay in \'Draft\' status until pre-qualifications have been added.')}
            />
            <Form className='card__content' id='form_cr8requirement_step1' aria-labelledby='hdr_cr8requirement_details'>

                {var_save_error &&
                    <Message error header={var_save_error} />
                }

                <FORM_INPUT
                    property='name'
                    label={t('Name')}
                    value={var_requirement.name}
                    onChange={onChange_input}
                    placeholder={t('Requirement name')}
                    maxLength={100}
                    errors={var_errors}
                />

                <FORM_TEXTAREA
                    property='description'
                    label={t('Description (optional)')}
                    value={var_requirement.description}
                    onChange={onChange_input}
                    placeholder={t('Description')}
                    maxLength={500}
                    errors={var_errors}
                />

            </Form>
            <div className='card__footer'>
                <Button className='primary' onClick={onClick_save}>{t('Save requirement')}</Button>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </div>
    );
}